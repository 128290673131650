import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDragControls, motion } from 'framer-motion';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import Background from '../../../primitives/backgrounds';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Color, { colors } from '../../../primitives/colors';
import AspectRatio from '../../../primitives/aspect-ratio';
import DatoAsset from '../../../primitives/asset';
import VideoPopover from '../../../primitives/video-popover';

type PhotoCarouselProps = Queries.PhotoCarouselPropsFragment;

const PhotoCarousel = ({ title, cards }: PhotoCarouselProps): JSX.Element => {
    const controls = useDragControls();
    const [isDragging, setIsDragging] = React.useState(false);
    const [pos, setPos] = React.useState<React.PointerEvent<HTMLDivElement>|null>(null);

    const checkPos = (e: React.PointerEvent<HTMLDivElement>, link: string): void => {
        if (pos && link) {
            if (pos.screenX === e.screenX) {
                if (process.env.NODE_ENV !== 'development') {
                    navigate(link);
                }
            }
        }
    };

    const startDrag = (event: React.MouseEvent): void => {
        setIsDragging(true);
        controls.start(event);
    };

    const endDrag = (): void => {
        setIsDragging(false);
    };

    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <Background
            breakpoints={{
                dt: {
                    gradient: 'greyAlphaRotated',
                },
            }}
            css={{
                position: 'relative',
                overflowX: 'hidden',
                userSelect: 'none',
            }}
        >
            <Container css={{
                paddingBottom: '54px',
                [breakpoints.mb]: {
                    paddingBottom: '39px',
                },
            }}
            >
                <Typography
                    fontWeight="light"
                    fontSize={{
                        dt: 4053,
                        tb: 3234,
                        mb: 2429,
                    }}
                    css={{
                        textAlign: 'center',
                        [breakpoints.mb]: {
                            width: '60%',
                            maxWidth: '205px',
                            margin: 'auto',
                        },
                    }}
                >
                    {title}
                </Typography>
            </Container>
            <div
                ref={ref}
                css={{
                    position: 'relative',
                    paddingBottom: '54px',
                    [breakpoints.mb]: {
                        paddingBottom: '34px',
                    },
                }}
            >
                <div
                    css={{
                        position: 'relative',
                        width: '100%',
                        minHeight: '305px',
                        pointerEvents: 'all',
                        [breakpoints.mb]: {
                            minHeight: 'unset',
                        },
                    }}
                    onPointerDown={startDrag}
                    onPointerUp={endDrag}
                    style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                >
                    <motion.div
                        css={{
                            minWidth: `calc(${691 * cards.length + 35 * (cards.length - 1)}px + max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw)) * 2)`,
                            display: 'flex',
                            paddingLeft: 'max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw))',
                            width: '100%',
                            [breakpoints.mb]: {
                                minWidth: `calc(${258 * cards.length + 24 * (cards.length - 1)}px + max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw)) * 2)`,
                                paddingLeft: 'max(5vw, calc(50% - calc(calc(1440px / 2) + 5vw) + 5vw))',
                            },
                        }}
                        dragControls={controls}
                        dragConstraints={ref}
                        drag="x"
                    >
                        {cards.map((card, idx) => (
                            <div
                                css={{
                                    maxWidth: '691px',
                                    width: '100%',
                                    flex: 'none',
                                    marginRight: idx !== cards.length - 1 ? '35px' : '0',
                                    display: 'flex',
                                    [breakpoints.mb]: {
                                        maxWidth: '258px',
                                        marginRight: idx !== cards.length - 1 ? '24px' : '0',
                                    },
                                }}
                                onPointerDown={setPos}
                                onPointerUp={(e) => {
                                    //checkPos(e, getLink(x?.link?.[0]?.link));
                                }}
                            >
                                <Color
                                    backgroundColor="grey50"
                                    css={{
                                        borderRadius: borderRadius.large,
                                        width: '100%',
                                        boxShadow: '0 34px 54px 0 rgba(0, 0, 0, 0.01)',
                                    }}
                                    role="group"
                                >
                                    <div css={{
                                        display: 'flex',
                                        height: '100%',
                                        [breakpoints.mb]: {
                                            flexDirection: 'column-reverse',
                                            justifyContent: 'flex-end',
                                        },
                                    }}
                                    >
                                        <div css={{
                                            padding: '28px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            flexGrow: 1,
                                            [breakpoints.mb]: {
                                                padding: '18px',
                                            },
                                        }}
                                        >
                                            <Typography
                                                fontSize={{
                                                    dt: 3234,
                                                    tb: 2429,
                                                    mb: 1627,
                                                }}
                                                css={{
                                                    [breakpoints.mb]: {
                                                        maxWidth: '60%',
                                                    },
                                                }}
                                                color="primaryDarkPurple"
                                            >
                                                {card.title}
                                            </Typography>
                                            <div css={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                            >
                                                <Typography
                                                    fontSize={{
                                                        dt: 1420,
                                                        mb: 1322,
                                                    }}
                                                    css={{
                                                        paddingTop: '12px',
                                                        paddingBottom: '26px',
                                                        [breakpoints.mb]: {
                                                            flexGrow: 1,
                                                            height: '100%',
                                                            paddingTop: '20px',
                                                            paddingBottom: '18px',
                                                        },
                                                    }}
                                                    color="primaryDarkPurple"
                                                >
                                                    <Typography
                                                        fontSize={{
                                                            dt: 2429,
                                                            mb: 1627,
                                                        }}
                                                        css={{
                                                            [breakpoints.mb]: {
                                                                maxWidth: '60%',
                                                            },
                                                        }}
                                                        color="grey200"
                                                    >
                                                        {card.jobDescription}
                                                    </Typography>
                                                </Typography>
                                                {card.video && (
                                                    <div>
                                                        <VideoPopover video={card.video?.[0]?.media}>
                                                            <span css={{
                                                                fontStyle: 'italic',
                                                            }}
                                                            >
                                                                Watch Video
                                                            </span>
                                                        </VideoPopover>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div css={{
                                            width: '100%',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderTopRightRadius: borderRadius.large,
                                            borderBottomRightRadius: borderRadius.large,
                                            transform: 'translateZ(0px)',
                                            [breakpoints.mb]: {
                                                borderBottomRightRadius: '0',
                                                borderTopLeftRadius: borderRadius.large,
                                            },
                                        }}
                                        >
                                            <AspectRatio
                                                css={{
                                                    height: '100%',
                                                    backgroundColor: colors.grey200,
                                                    borderTopRightRadius: borderRadius.large,
                                                    borderBottomRightRadius: borderRadius.large,
                                                    [breakpoints.mb]: {
                                                        borderBottomRightRadius: '0',
                                                        borderTopLeftRadius: borderRadius.large,
                                                    },
                                                }}
                                                breakpoints={{
                                                    dt: {
                                                        width: 343,
                                                        height: 305,
                                                    },
                                                    mb: {
                                                        width: 258,
                                                        height: 180,
                                                    },
                                                }}
                                            >
                                                <DatoAsset
                                                    css={{
                                                        height: '100%',
                                                        width: '100%',
                                                        borderTopRightRadius: borderRadius.large,
                                                        borderBottomRightRadius: borderRadius.large,
                                                        overflow: 'hidden',
                                                        pointerEvents: 'none',
                                                        transition: '10s',
                                                        transform: 'scale(1.001)',
                                                        '[role="group"]:hover &': {
                                                            transform: 'scale(1.1)',
                                                        },
                                                        [breakpoints.mb]: {
                                                            borderBottomRightRadius: '0',
                                                            borderTopLeftRadius: borderRadius.large,
                                                        },
                                                    }}
                                                    asset={card.image}
                                                />
                                            </AspectRatio>
                                        </div>
                                    </div>
                                </Color>
                            </div>
                        ))}
                    </motion.div>
                </div>
            </div>
        </Background>
    );
};

export default PhotoCarousel;

export const query = graphql`
    fragment PhotoCarouselProps on DatoCmsPhotoCarousel {
        title
        cards {
            title
            jobDescription
            image {
                ...DatoCmsInternalAsset
            }
            video {
                ...MediaItemProps
            }
        }
    }
`;
